
import { defineComponent, watch, computed, ref, toRefs } from 'vue';
import { Form } from "vee-validate";
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue"
import Select from "@/components/forms/Select.vue"
import InputNumber from "@/components/forms/InputNumber.vue"
import * as Validations from "yup";
import EquivalenciesTable from "./equivalencies/EquivalenciesTable.vue"
import Equivalence from "@/models/article/equivalence/Equivalence"
import newArticleModule from "@/store/modules/article/modules/new-article"
import _ from "lodash"
export default defineComponent({
    components: {
        SelectFilterRemote
        , Form
        , InputNumber
        , Select
        , EquivalenciesTable
    },
     props: {
        mode: {
            type: String,
            required: false,
            default: () => "V"
        }
    },
    setup(props) {
        const {mode} = toRefs(props);
        const isReadOnly = computed(() => mode.value == "V");
        const formEquivalencies = ref();
         const validationSchema =  Validations.object().shape({
            productId: Validations.string().default('').required()
            , unitOfMeasurementId: Validations.string().default('').required()
            , equivalece: Validations.number().required().positive()
        });

        const save = (form: Equivalence, {resetForm}) => {
            newArticleModule.ADD_EQUIVALENCE(form);
            resetForm();
        }
        const search = _.debounce((filtername) => newArticleModule.getComboProduct(filtername), 400);

        const changeProduct = (id: string) => {
            const product = productsOptions.value.find(p => p.id == id);
            newArticleModule.SET_PRODUCT_SELECT(product);
        }
        const cancelEdit = () => {
            newArticleModule.RESET_INITIAL_VALUE();
            formEquivalencies.value.resetForm();
        }
        const productsOptions = computed(() => newArticleModule.selects.products);
        const unitOfMeasurementsOptions = computed(() => newArticleModule.selects.unitOfMeasurement);

        const isEditEquivalence = computed(() => newArticleModule.isEditEquivalence)

        watch(() => newArticleModule.getInitialValues, (newValue) => {
            if(newValue){
                formEquivalencies.value.setFieldValue('productId',newValue.productId)
                formEquivalencies.value.setFieldValue('unitOfMeasurementId',newValue.unitOfMeasurementId)
                formEquivalencies.value.setFieldValue('equivalece',newValue.equivalece)
            }
        })

        watch(() => unitOfMeasurementsOptions.value, (newValue) => {
            const option = newValue[0];
            formEquivalencies.value.setFieldValue('unitOfMeasurementId',option.id)
        })
        return {
            //Computeds
            save
            , search
            , changeProduct
            , cancelEdit
            , validationSchema
            , productsOptions
            , unitOfMeasurementsOptions
            , formEquivalencies
            , isEditEquivalence
            , isReadOnly
        }
    },
})
