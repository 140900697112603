import { getMessageError } from "@/core/helpers/messageFromPulmeros";
import ProductInfoOption from "@/models/products/ProductInfoOption";
import OptionSelect from "@/models/shared/OptionSelect";
import { AxiosResponse } from "axios";
import ApiService from "../ApiService";
import GeneralService from "../interfaces/GeneralService";
import MessageService from "../interfaces/MessageService";
import SwalMessageService from "../SwalMessageService";
import ProductSearchComboService from "./interfaces/productSearchComboService";

export default class ProductsService implements GeneralService, ProductSearchComboService {
    apiController = "/Products";
    messageService: MessageService = new SwalMessageService();

    async searchProducts(filterName: string): Promise<AxiosResponse<ProductInfoOption[]>> {
        return  ApiService.get<Array<OptionSelect>>(this.apiController, 
            `combo?filter=${filterName}`
        ).then( resp => {
            resp.data = resp.data.map(a => {
                const article = {
                    id: a.id,
                    name: a.code + ' ' + a.name,
                    price: a.price,
                    unitOfMeasurementId: a.unitOfMeasurementId,
                    unitOfMeasurementName: a.unitOfMeasurementName,
                    minimunDiscount: a.minimunDiscount,
                    maximunDiscount: a.maximunDiscount,
                    publicPrice: a.publicPrice,
                    buyerPrice: a.buyerPrice,
                    wholesalePrice: a.wholesalePrice,
                    virtualExistence: a.virtualExistence,
                    currentExistence: a.currentExistence,
                    isInventory: a.isInventory,
                    alternateCode: a.alternateCode,
                    supplierKey: a.supplierKey,
                    productKey: a.productKey,
                    code: a.code,
                    cost: a.cost,
                    colorId: a.colorId,
                    colorName: a.colorName,
                    taxId: a.taxId,
                    taxName: a.taxName,
                    taxValue: a.taxValue,
                    discountRateSupplier: a.discountRateSupplier
                };

                return article;
            })
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
}