<template>
    <div>
        <!-- ref({currentExistence: 0, publicPrice: 0, aluminumPrice: 0, wholesalePrice: 0}) -->
        <div class="card card-flush pb-5">
            <div class="card-header pt-5">
                <div class="card-title d-flex flex-column">
                    <div class="d-flex align-items-center">
                        <span class="fs-4 fw-semibold text-gray-400 me-1 align-self-start">$</span>
                        <span class="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{{pricingData.publicPrice}}</span>
                    </div>
                    <span class="text-gray-400 pt-1 fw-semibold fs-6">Precio Público</span>
                </div>
            </div>                                
        </div>
        <div class="card card-flush pb-5">
            <div class="card-header pt-5">
                <div class="card-title d-flex flex-column">
                    <div class="d-flex align-items-center">
                        <span class="fs-4 fw-semibold text-gray-400 me-1 align-self-start">$</span>
                        <span class="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{{pricingData.aluminumPrice}}</span>
                    </div>
                    <span class="text-gray-400 pt-1 fw-semibold fs-6">Precio Aluminiero</span>
                </div>
            </div>                                
        </div>
        <div class="card card-flush pb-5">
            <div class="card-header pt-5">
                <div class="card-title d-flex flex-column">
                    <div class="d-flex align-items-center">
                        <span class="fs-4 fw-semibold text-gray-400 me-1 align-self-start">$</span>
                        <span class="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{{pricingData.wholesalePrice}}</span>
                    </div>
                    <span class="text-gray-400 pt-1 fw-semibold fs-6">Precio Mayoreo</span>
                </div>
            </div>                                
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: "pricing-visualizer",
    props: {
        pricingData: {
            type: Object,
            required: true
        }
    },
    setup() {
        let temp = 123;
    },
})
</script>
