
import { computed, defineComponent, reactive, toRefs } from "vue";
import ModuleMaster from "@/components/c-module-master/module-master.vue";
import Datatable from "@/components/c-datatable/Datatable.vue";
import Datarow from "@/components/c-datatable/Datarow.vue";
import { formatToDatatable } from "@/common/formaterToDatatable";
import newArticleModule from "@/store/modules/article/modules/new-article"

export default defineComponent({
    components:{
        ModuleMaster
        , Datatable
        , Datarow
    },
     props: {
        mode: {
            type: String,
            required: false,
            default: () => "V"
        }
    },
    setup(props){
        const {mode} = toRefs(props);
        const isReadOnly = computed(() => mode.value == "V");
        const configTable = reactive([
            { PropName: "productId", HeadLabel: "id", Type: "text", VisibleInGrid: false },
            { PropName: "productName", HeadLabel: "Nombre", Type: "text", VisibleInGrid: true },
            { PropName: "unitOfMeasurementName", HeadLabel: "Unidad de Medida", Type: "text", VisibleInGrid: true },
            { PropName: "equivalece", HeadLabel: "Cantidad", Type: "text", VisibleInGrid: true },
        ]);
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });


        const deleteEquivalence = (productId: string) => {
            //eliminar equivalencia
            newArticleModule.REMOVE_EQUIVALENCE(productId);
        }
        const handleChangePage = (page: number) => {
            //await goToPage(page);
            newArticleModule.CHANGE_PAGE(page);
        }
        const editEquivalence = (productId: string) => {
            newArticleModule.EDIT_EQUIVALENCE(productId);
        }

        //COMPUTADAS
        const totalRecords = computed(() => newArticleModule?.equivalencies?.length ?? 0);
        const records = computed(() => newArticleModule.getEquivalenciesTable);
        const selectedPage = computed(() =>  newArticleModule.paginations.equivalencies.currentPage);
        const itemsPerPage = computed(() => newArticleModule.paginations.equivalencies.pageSize);
        const renderRows = computed(() => {
            return formatToDatatable(records.value, configTable,'', 'productId');
        });

        return {
            deleteEquivalence
            , handleChangePage
            , editEquivalence
            , configTable
            , headers
            , totalRecords
            , selectedPage
            , itemsPerPage
            , renderRows
            , isReadOnly
        }
    }
})
