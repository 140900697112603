
import { defineComponent, computed, ref, toRefs, onMounted, reactive } from 'vue';
import ModuleMaster from "@/components/c-module-master/module-master.vue";
import Datatable from "@/components/c-datatable/Datatable.vue";
import Datarow from "@/components/c-datatable/Datarow.vue";
import _ from "lodash"
import ListOutputsByProducts from '@/models/outputs/ListOutputsByProducts';
import ApiService from '@/core/services/ApiService';
import { AxiosResponse } from 'axios';
import { formatToDatatable } from "@/common/formaterToDatatable";
import PaginationResponse from '@/models/general/PaginationResponse';

export default defineComponent({
    components: {
        ModuleMaster
        , Datatable
        , Datarow
    },
     props: {
        mode: {
            type: String,
            required: false,
            default: () => "V"
        },
        article: {
            type: String,
            required: false
        },
    },
    setup(props) {

        let records = ref<ListOutputsByProducts[]>([]);
        let numItemsPaginator = ref(0);
        let itemsPerPage = ref(5);
        let currentPage = ref(1);
        const {article} = toRefs(props);

        const configTable = reactive([
            { PropName: "productId", HeadLabel: "id", Type: "text", VisibleInGrid: false },
            { PropName: "folio", HeadLabel: "Folio", Type: "text", VisibleInGrid: true },
            { PropName: "personName", HeadLabel: "Proveedor", Type: "text", VisibleInGrid: true },
            { PropName: "productName", HeadLabel: "Producto", Type: "text", VisibleInGrid: true },
            { PropName: "price", HeadLabel: "Precio", Type: "text", VisibleInGrid: true },
            { PropName: "quantity", HeadLabel: "Cantidad", Type: "text", VisibleInGrid: true },
            { PropName: "total", HeadLabel: "Total", Type: "moneda", VisibleInGrid: true },
        ]);
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const initialV = computed(() => {
            return article;
        });

        const handleChangePage = (page: number) => {
            currentPage.value = page;
            getOutputs();
        }

        const getOutputs = () => {

        let params = `CurrentPage=${currentPage.value}&PageSize=7`;

        ApiService.get<PaginationResponse<ListOutputsByProducts[]>>('/PurchaseOrders/Detail/GetPagedListByProductId', 
            `${article.value}?${params}`
        )
        .then(resp => {
            records.value = resp.data.data;
            numItemsPaginator.value = resp.data.totalRecords;
            itemsPerPage.value = 5;
            currentPage.value = resp.data.currentPage;
            return resp;
        }).catch(error => {
            return { data: null} as AxiosResponse;
        });
        }

        const renderRows = computed(() => {
            return formatToDatatable(records.value, configTable,'', 'productId');
        });

        onMounted(() => {
            getOutputs();
        });
       
        return {
            handleChangePage
            ,getOutputs
            
            ,renderRows
            ,headers
            ,initialV
            ,records
            ,numItemsPaginator
            ,itemsPerPage
            ,currentPage
        }
    },
})
