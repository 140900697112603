<template>
    <div class="tab-pane fade show active" id="tab_article_description" role="tab-panel">
        <div class="d-flex flex-column gap-7 gap-lg-10">
            <div class="card card-flush py-4">
                <div class="card-body pt-0">
                    <div class="d-flex flex-wrap gap-5 mt-10">
                        <div class="fv-row w-100 flex-md-root">
                            <label class="form-label required">Código</label>
                            <!-- <input type="text" class="form-control mb-2"/> -->
                            <Field  name="ProductKey" v-slot="{value, field, errorMessage}">
                                <el-form-item :error="errorMessage">
                                    <el-input
                                        v-bind="field"
                                        type="text" 
                                        placeholder="Código"
                                        :validate-event="false"
                                        :disabled="readOnly"
                                        :model-value="value"
                                    />
                                </el-form-item>
                            </Field>
                        </div>
                        <div class="fv-row w-100 flex-md-root">
                            <div style="display: flex;">
                                <label class="form-label">Barras/Alterno</label>
                                <div class="form-check form-check-custom form-check-solid form-check-sm" style="margin-bottom: 5px; margin-left: 16px;">
                                    <input class="form-check-input" type="checkbox" value="" checked :disabled="readOnly"/>
                                    <label class="form-check-label" for="flexRadioLg">Completo</label>
                                </div>
                            </div>
                            <Field name="AlternateCode" v-slot="{value, field, errorMessage}">
                                <el-form-item :error="errorMessage">
                                    <el-input
                                        v-bind="field"
                                        type="text" 
                                        placeholder="Barras/Alterno"
                                        :validate-event="false"
                                        :disabled="readOnly"
                                        :model-value="value"
                                    />
                                </el-form-item>
                            </Field>
                        </div>
                        <div class="fv-row w-100 flex-md-root">
                            <label class="form-label">Clave Producto Servicio</label>
                            <Field  name="SupplierKey" v-slot="{value, field, errorMessage}">
                                <el-form-item :error="errorMessage">
                                    <el-input
                                        v-bind="field"
                                        type="text" 
                                        placeholder="Clave Producto Servicio"
                                        :validate-event="false"
                                        :disabled="readOnly"
                                        :model-value="value"
                                    />
                                </el-form-item>
                            </Field>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap gap-5 mt-10">
                        <div class="fv-row w-100 flex-md-root">
                            <label class="form-label required">Nombre</label>
                            <Field  name="Name" v-slot="{value, field, errorMessage}">
                                <el-form-item :error="errorMessage">
                                    <el-input
                                        v-bind="field"
                                        type="text" 
                                        placeholder="Nombre"
                                        :validate-event="false"
                                        :disabled="readOnly"
                                        :model-value="value"
                                    />
                                </el-form-item>
                            </Field>
                        </div>
                        <div class="fv-row w-100 flex-md-root">
                            <label class="required form-label">Almacén</label>
                            <SelectOption 
                                name="WarehouseId"
                                :options="wareHouseOptions"
                                :disabled="readOnly"
                            />
                        </div>
                    </div>
                    <!-- <div class="mt-10">
                        <label class="form-label">Descripción</label>
                        <input type="text" name="product_name" class="form-control mb-2" placeholder="Descripción" value="" />
                    </div>  -->
                    <div class="d-flex flex-wrap gap-5 mt-10">
                        <div class="fv-row w-100 flex-md-root">
                            <label class="required form-label">Unidad</label>
                            <SelectOption 
                                name="UnitOfMeasurementId"
                                :options="unitMeasurementOptions"
                                :disabled="readOnly"
                                @change="onChangeUnitMeasurement"
                            />
                        </div>
                        <div class="fv-row w-100 flex-md-root">
                            <label class="form-label">Peso</label>
                            <Field  name="Weight" v-slot="{value, field, errorMessage}">
                                <el-form-item :error="errorMessage">
                                    <el-input
                                        v-bind="field"
                                        type="text" 
                                        placeholder="Peso"
                                        :validate-event="false"
                                        :disabled="readOnly"
                                        :model-value="value"
                                    />
                                </el-form-item>
                            </Field>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap gap-5 mt-10">
                        <div class="fv-row w-100 flex-md-root">
                            <label class="required form-label">Familia</label>
                            <SelectOption 
                                name="FamilyId"
                                :options="familyOptions"
                                :disabled="readOnly"
                                @change="onChangeFamily"
                            />
                        </div>
                        <div class="fv-row w-100 flex-md-root">
                            <label class="required form-label">Clase</label>
                            <SelectOption 
                                name="SubFamilyId"
                                :options="subFamilyOptions"
                                :disabled="readOnly"
                                @change="onChangeSubFamily"
                            />
                        </div>
                    </div>
                    <div class="d-flex flex-wrap gap-5 mt-10">
                        <div class="fv-row w-100 flex-md-root">
                            <label class="required form-label">Línea</label>
                            <SelectOption 
                                name="LineId"
                                :options="lineOptions"
                                :disabled="readOnly"
                                @change="onChangeLine"
                            />
                        </div>
                        <div class="fv-row w-100 flex-md-root">
                            <label class="required form-label">Proveedor</label>
                            <SelectOption 
                                name="SupplierId"
                                :options="supplierOptions"
                                :disabled="readOnly"
                            />
                        </div>
                    </div>
                    <div class="d-flex flex-wrap gap-5 mt-10">
                        <div class="fv-row w-100 flex-md-root">
                            <label class="required form-label">Color</label>
                            <SelectOption 
                                name="ColorId"
                                :options="colorOptions"
                                :disabled="readOnly"
                            />
                        </div>
                        <div class="fv-row w-100 flex-md-root">
                            <label class="form-label">C.U.</label>
                            <Field  name="cu" v-slot="{value, field, errorMessage}">
                                <el-form-item :error="errorMessage">
                                    <el-input
                                        v-bind="field"
                                        type="text" 
                                        placeholder="C.U."
                                        :validate-event="false"
                                        :disabled="true"
                                        :model-value="value"
                                    />
                                </el-form-item>
                            </Field>
                        </div>
                    </div>
                    <!--<div class="d-flex flex-wrap gap-5 mt-10">
                        <div class="fv-row w-100 flex-md-root">
                            <label class="form-label">Alta</label>
                            <Field  name="createdAt" v-slot="{value, field, errorMessage}">
                                <el-form-item :error="errorMessage">
                                    <el-input
                                        v-bind="field"
                                        type="text" 
                                        placeholder="Nombre"
                                        :validate-event="false"
                                        :disabled="true"
                                        :model-value="value"
                                    />
                                </el-form-item>
                            </Field>
                        </div>
                        <div class="fv-row w-100 flex-md-root">
                            <label class="form-label">Última Modificación</label>
                            <Field  name="lastUpd" v-slot="{value, field, errorMessage}">
                                <el-form-item :error="errorMessage">
                                    <el-input
                                        v-bind="field"
                                        type="text" 
                                        placeholder="Nombre"
                                        :validate-event="false"
                                        :disabled="true"
                                        :model-value="value"
                                    />
                                </el-form-item>
                            </Field>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, ref, onBeforeMount, toRefs } from 'vue';
import { Field } from "vee-validate";
import SelectOption from "../../../components/c-form-elements/SelectOption.vue";
import ApiService from "@/core/services/ApiService";
import { getMessageError } from '@/core/helpers/messageFromPulmeros';
import SwalMessageService from "@/core/services/SwalMessageService";

export default defineComponent({
    name: "description-tab-content",
    components: {
        Field,
        SelectOption
    },
    props: {
        parentForm: {
            type: Object,
            required: false
        },
        mode: {
            type: String,
            required: false,
            default: () => "V"
        }
    },
    setup(props) {
        const { parentForm, mode } = toRefs(props);
        let warehouses = ref([]);
        let unitMeasurement = ref([]);
        let families = ref([]);
        let subFamilies = ref([]);
        let lines = ref([]);
        let suppliers = ref([]);
        let colors = ref([]);
        let currentFamily = ref("");
        let currentSubFamily = ref("");
        let currentLine = ref("");
        const messageService = new SwalMessageService();

        //HOOKS
        onBeforeMount(async() => {
            await getComboOptions("Warehouses", warehouses);
            await getComboOptions("MeasurementUnits", unitMeasurement);
            await getComboOptions("families", families);
            await getComboOptions("Suppliers", suppliers);
            await getComboOptions("Colors", colors);
        });

        //COMPUTEDS
        const readOnly = computed(() => mode.value == "V");
        const wareHouseOptions = computed(() => warehouses.value);
        const unitMeasurementOptions = computed(() => unitMeasurement.value);
        const familyOptions = computed(() => families.value);
        const subFamilyOptions = computed(() => subFamilies.value);
        const lineOptions = computed(() => lines.value);
        const supplierOptions = computed(() => suppliers.value);
        const colorOptions = computed(() => colors.value);

        //METHODS
        const getComboOptions = async(resource, container, parentId = "") => {
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let url = `${resource}/${parentParam}combo`;
            ApiService.get(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const onChangeFamily = async(id) => {
            if(id) {
                if(currentFamily.value != id) {
                    if(currentSubFamily.value != "") {
                        resetFormField("SubFamilyId", "");
                    }

                    if(currentLine.value != "") {
                        resetFormField("LineId", "");
                    }
                }
                currentFamily.value = id;
                fillComboSubfamilys(id);
                // let parent = `${currentFamily.value}`;
                // await getComboOptions("SubFamilies", subFamilies, parent);
            }
        }

        const onChangeSubFamily = async(id) => {
            if(id) {
                if(currentSubFamily.value != id && currentLine.value != "") {
                    resetFormField("LineId", "");
                }
                currentSubFamily.value = id;
                fillComboLines(currentFamily.value, id);
                // let parent = `${currentFamily.value}/${id}`;
                // await getComboOptions("lines", lines, parent);
            }
        }

        const fillComboSubfamilys = async(famId) => {
            let parent = `${famId}`;
            await getComboOptions("SubFamilies", subFamilies, parent);
        }

        const fillComboLines = async(famId, subFamId) => {
            let parent = `${famId}/${subFamId}`;
            await getComboOptions("lines", lines, parent);
        }

        const onChangeLine = async(id) => {
            if(id) {
                currentLine.value = id;
            }
        }

        const resetFormField = (fieldName, value) => {
            parentForm.value.setFieldValue(fieldName, value);
        }

        const onChangeUnit = (val) => {
            let url = `MeasurementUnits/${val}`;
            ApiService.get(url).then(res => {
                resetFormField("cu", res.data.code);
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const onChangeUnitMeasurement = (val) => {

            const item = unitMeasurement.value.filter((value, index) => value.id == val)
            let descriptionCU = "C.U."

            if(item[0] != null){
                if(item[0].taxCode != null && item[0].taxCode != ""){
                    descriptionCU = item[0].taxCode;
                }              
            }
            resetFormField("cu", descriptionCU);
        }

        return {
            wareHouseOptions
            , familyOptions
            , unitMeasurementOptions
            , subFamilyOptions
            , lineOptions
            , supplierOptions
            , colorOptions
            , readOnly

            //methods
            , onChangeFamily
            , onChangeSubFamily
            , onChangeLine
            , fillComboSubfamilys
            , fillComboLines
            , onChangeUnit
            , onChangeUnitMeasurement
        }
    },
})
</script>
