import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "tab-content" }
const _hoisted_2 = {
  class: "tab-pane fade show",
  id: "tab_inputs",
  role: "tab-panel"
}
const _hoisted_3 = { class: "card card-flush py-4" }
const _hoisted_4 = { class: "card-body pt-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datarow = _resolveComponent("Datarow")!
  const _component_Datatable = _resolveComponent("Datatable")!
  const _component_ModuleMaster = _resolveComponent("ModuleMaster")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ModuleMaster, {
            requireSearchField: false,
            requireAddButton: false,
            requirePaginator: true,
            useFilters: false,
            numItemsPaginator: _ctx.numItemsPaginator,
            selectedPage: _ctx.currentPage,
            itemsPerPage: _ctx.itemsPerPage,
            fnCallbackChangePage: _ctx.handleChangePage
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Datatable, {
                headers: _ctx.headers,
                requireActions: false
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.renderRows, (row) => {
                    return (_openBlock(), _createBlock(_component_Datarow, {
                      key: row.ID,
                      dataRow: row,
                      showCrudActions: false
                    }, null, 8, ["dataRow"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["headers"])
            ]),
            _: 1
          }, 8, ["numItemsPaginator", "selectedPage", "itemsPerPage", "fnCallbackChangePage"])
        ])
      ])
    ])
  ]))
}