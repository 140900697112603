<template>
    <structure-edit-module>
        <div id="kt_app_content" class="app-content flex-column-fluid">
            <div id="kt_app_content_container" class="app-container container-xxl">
                <Form
                    class="form d-flex flex-column flex-lg-row"
                    as="el-form" 
                    :validation-schema="validationSchema" 
                    :initial-values="getInitialValues" 
                    @submit="onSubmit" 
                    ref="form"
                >
                    <div class="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
                        <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{{ getTitle }}</h1>
                        <add-image @onImageLoaded="onImageLoaded" :imageLoadUrl="urlImagen" :mode="getCurrentAction" />
                        <div class="card card-flush pb-5">
                            <div class="card-header pt-5">
                                <div class="card-title d-flex flex-column">
                                    <div class="d-flex align-items-center">
                                        <span class="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{{ getGeneralPricingData.currentExistence }}</span>
                                        <span class="fs-4 fw-semibold text-gray-400 me-1 align-self-start"> Unidades</span>
                                    </div>
                                    <span class="text-gray-400 pt-1 fw-semibold fs-6">Existencia Total</span>
                                </div>
                            </div>                                
                        </div>
                        <pricing-visualizer :pricingData="getGeneralPricingData"/>
                    </div>
                    <div class="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                        <edit-nav-tabs @onChangeTab="changeTab" :mode="getCurrentAction"/>
                        <div class="tab-content">
                            <description-tab-content :parentForm="getForm" :mode="getCurrentAction" ref="descriptionTab"/>
                            <pricing-tab-content :mode="getCurrentAction" :initialValues="getInitialValues" @onupdgeneralPricingData = "onupdgeneralPricingData"/>
                            <general-tab-content :mode="getCurrentAction"/>
                            <EquivalenciesTabContent :mode="getCurrentAction"></EquivalenciesTabContent>
                            <InputsTabContent v-if="getCurrentAction == 'V'" :mode="getCurrentAction" :article="articleId"></InputsTabContent>
                            <OutputsTabContent v-if="getCurrentAction == 'V'"  :mode="getCurrentAction" :article="articleId"></OutputsTabContent>
                            <SalesTabContent v-if="getCurrentAction == 'V'" :mode="getCurrentAction" :article="articleId"></SalesTabContent>
                            <PurchaseTabContent v-if="getCurrentAction == 'V'"  :mode="getCurrentAction" :article="articleId"></PurchaseTabContent>
                        </div>

                        <div v-if="getCurrentAction != 'V'" class="d-flex justify-content-end">
                            <a @click="backToMain" id="kt_ecommerce_add_product_cancel" class="btn btn-light me-5">Cancelar</a>
                            <button class="btn btn-primary">
                                <span class="indicator-label">Guardar Cambios</span>
                                <span class="indicator-progress">Guardando... 
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>
                        </div>
                    </div>
                    <button hidden type="submit" ref="submitBtn"></button>
                </Form>
            </div>
        </div>
    </structure-edit-module>
</template>

<script>
import { computed, defineComponent, onBeforeMount, onMounted, ref, toRefs, reactive } from 'vue';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import EditNavTabs from "./edit-nav-tabs.vue";
import DescriptionTabContent from "./description-tab-content.vue";
import PricingTabContent from "./pricing-tab-content.vue";
import GeneralTabContent from "./general-tab-content.vue";
import EquivalenciesTabContent from "./equivalencies-tab-content.vue";
import OutputsTabContent from "./outputs-tab-content.vue";
import InputsTabContent from "./inputs-tab-content.vue";
import SalesTabContent from "./sales-tab-content.vue";
import PurchaseTabContent from "./purchase-tab-content.vue";
import AddImage from './add-image.vue';
import PricingVisualizer from './pricing-visualizer.vue';
import { useRouter } from "vue-router";
import { Form } from "vee-validate";
import * as Validations from "yup";
import ApiService from '@/core/services/ApiService';
import SwalMessageService from "@/core/services/SwalMessageService";
import { getMessageError } from '@/core/helpers/messageFromPulmeros';
import StructureEditModule from './structure-edit-module.vue';
import axios from 'axios';
import newArticleModule from "@/store/modules/article/modules/new-article"

export default defineComponent({
    props: {
        C0: {
            type: String,
            required: true
        }
    },
    components: {
        StructureEditModule,
        EditNavTabs,
        DescriptionTabContent,
        PricingTabContent,
        GeneralTabContent,
        AddImage,
        PricingVisualizer,
        Form
        , EquivalenciesTabContent
        , OutputsTabContent
        , InputsTabContent
        , SalesTabContent
        , PurchaseTabContent
    },
    setup(props) {
        const controller = "Products";
        const { C0 } = toRefs(props);
        const urlImagen = ref("");
        const router = useRouter();
        const messageService = new SwalMessageService();
        let articleId = ref(null);
        let descriptionTab = ref(null);
        let activeTab = ref(1);
        let currentAction = ref("N");
        let messageToBreadcrumb = ref("");
        const form = ref(null);
        const submitBtn = ref(null);
        let readOnly = ref(true);
        let images = null;
        let generalPricingData = ref({currentExistence: 0, publicPrice: 0, aluminumPrice: 0, wholesalePrice: 0})
        let initialValues = ref({
            SupplierCode: ""
            , SizeBarCode: ""
            , ColorCode: ""
            , Name: ""
            , WarehouseId: ""
            , FamilyId: ""
            , SubFamilyId: ""
            , LineId: ""
            , UnitOfMeasurementId: ""
            , Price: ""
            , AlternateCode: ""
            , SupplierKey: ""
            , ProductKey: ""
            , Description: ""
            , ColorId: ""
            , SupplierId: ""
            , MinimumQuantity: ""
            , MaximumQuantity: ""
            , Weight: ""
            , CurrencyId: ""
            , TaxId: ""
            //, ColorPercentageId: "" 
            , isInventory: false
            , ProfitPercentage: "" 
            , SupplierPrice: ""
            , Cost: ""
            , DiscountRateSupplier: ""
            , ProfitPercentage1: ""
            , ProfitPercentage2: ""
            , ProfitPercentage3: ""
            , cu: ""
            , articleId: ""
        });
        const equivalencies = computed(() => newArticleModule.equivalencies)
        const validationSchema = Validations.object().shape({
            // SupplierCode: Validations.string().required().label("SupplierCode"),
            // SizeBarCode: Validations.string().required().label("SizeBarCode"),
            // ColorCode: Validations.string().required().label("ColorCode"),
            Name: Validations.string().required().label("Name"),
            WarehouseId: Validations.string().required().label("WarehouseId"),
            FamilyId: Validations.string().required().label("FamilyId"),
            SubFamilyId: Validations.string().required().label("SubFamilyId"),
            LineId: Validations.string().required().label("LineId"),
            UnitOfMeasurementId: Validations.string().required().label("UnitOfMeasurementId"),
            AlternateCode: Validations.string().label("AlternateCode"),
            SupplierKey: Validations.string().label("SupplierKey"),
            ProductKey: Validations.string().required().min(10).label("ProductKey"),
            Description: Validations.string().required().label("Description"),
            ColorId: Validations.string().required().label("ColorId"),
            SupplierId: Validations.string().required().label("SupplierId"),
            MinimumQuantity: Validations.number().required().label("MinimumQuantity"),
            MaximumQuantity: Validations.number().required().label("MaximumQuantity"),
            Weight: Validations.number().required().label("Weight"),
            CurrencyId: Validations.string().required().label("CurrencyId"),
            TaxId: Validations.string().required().label("TaxId"),
            //ColorPercentageId: Validations.string().required().label("ColorPercentageId"), 
            isInventory: Validations.boolean().optional(),
            ProfitPercentage: Validations.number().required().label("ProfitPercentage"), 
            SupplierPrice: Validations.number().required().label("SupplierPrice"),
            Cost: Validations.number().required().label("Cost"),
            DiscountRateSupplier: Validations.string().required().label("DiscountRateSupplier"),
            ProfitPercentage1: Validations.number().required().label("ProfitPercentage1"),
            ProfitPercentage2: Validations.number().required().label("ProfitPercentage2"),
            ProfitPercentage3: Validations.number().required().label("ProfitPercentage3"),
            AluminumPrice: Validations.number().label("AluminumPrice"),
            PublicCostSale: Validations.number().label("PublicCostSale"),
            PublicPrice: Validations.number().label("PublicPrice"),
            WholesalePrice: Validations.number().label("WholesalePrice"),
            createdAt: Validations.string().label("createdAt"),
            cu: Validations.string().label("cu"),
            lastUpd: Validations.string().label("lastUpd")
        });

        /** HOOKS */
        onBeforeMount(() => {
            if(C0.value) {
                let params = JSON.parse(atob(C0.value));
                if(params) {
                    currentAction.value = params.action;
                    readOnly.value = currentAction.value == "V";
                    switch(currentAction.value) {
                        case "N":
                            messageToBreadcrumb.value = "Nuevo Artículo";
                            //limpiar las equivalencias
                            newArticleModule.RESET_EQUIVLENCIES();
                            newArticleModule.RESET_INITIAL_VALUE();
                            break;
                        case "M":
                        case "V":
                            articleId.value = params.articleId;
                            messageToBreadcrumb.value = `Artículo ${params.articleCode}`;
                            fillForm();
                            break;
                    }
                }
            }
        });

        onMounted(() => {
            setCurrentPageBreadcrumbs(messageToBreadcrumb.value, ["Catálogos", {name:"Artículos", to: "settings/catalogs/articles"}]);
        });

        /** COMPUTEDS */

        const activeDescriptionTab = computed(() => true);
        const activePricingTab = computed(() => true);
        const activeGeneralTab = computed(() => true);

        const getTitle = computed(() => {
            return currentAction.value == "N" ? "Nuevo Artículo" : currentAction.value == "M" ? "Editar Artículo" : "Visualizar Artículo";
        })

        const getCurrentAction = computed(() => currentAction.value);
        const getForm = computed(() => form.value);
        const getInitialValues = computed(() => initialValues.value);
        const getReadOnly = computed(() => readOnly.value);
        const getGeneralPricingData = computed(() => generalPricingData.value);

        /** METHODS */

        const changeTab = (tabId) => {
            activeTab.value = tabId;
        }

        const backToMain = () => {
            let dataProps = `""`;
            router.push({
                name: 'modArticles',
                params: {
                    C0: btoa(dataProps)
                }
            });
        }

        const fillForm = async() => {
            let url = `${controller}/${articleId.value}`;
            ApiService.query(url).then(res => {
                if(res.status == 200) {
                    initialValues.value.articleId = articleId.value;
                    initialValues.value.SupplierCode = res.data.supplierCode;
                    initialValues.value.SizeBarCode = res.data.sizeBarCode;
                    initialValues.value.ColorCode = res.data.colorCode;
                    initialValues.value.Name = res.data.name;
                    initialValues.value.WarehouseId = res.data.warehouseId;
                    initialValues.value.FamilyId = res.data.familyId;
                    initialValues.value.SubFamilyId = res.data.subFamilyId;
                    initialValues.value.LineId = res.data.lineId;
                    initialValues.value.UnitOfMeasurementId = res.data.unitOfMeasurementId;
                    initialValues.value.Price = res.data.price;
                    initialValues.value.AlternateCode = res.data.alternateCode;
                    initialValues.value.SupplierKey = res.data.supplierKey;
                    initialValues.value.ProductKey = res.data.productKey != "" ? res.data.productKey : res.data.code;
                    initialValues.value.Description = res.data.description;
                    initialValues.value.ColorId = res.data.colorId;
                    initialValues.value.SupplierId = res.data.supplierId;
                    initialValues.value.MinimumQuantity = res.data.minimumQuantity;
                    initialValues.value.MaximumQuantity = res.data.maximumQuantity;
                    initialValues.value.Weight = res.data.weight;
                    initialValues.value.CurrencyId = res.data.currencyId;
                    initialValues.value.TaxId = res.data.taxId;
                    //initialValues.value.ColorPercentageId = res.data.colorPercentageId == "00000000-0000-0000-0000-000000000000" ? "" : res.data.colorPercentageId;
                    
                    initialValues.value.isInventory = res.data.isInventory;
                    initialValues.value.ProfitPercentage = res.data.profitPercentage; 
                    initialValues.value.SupplierPrice = res.data.supplierPrice;
                    initialValues.value.Cost = res.data.cost;
                    initialValues.value.DiscountRateSupplier = res.data.discountRateSupplier;
                    initialValues.value.ProfitPercentage1 = res.data.profitPercentage1;
                    initialValues.value.ProfitPercentage2 = res.data.profitPercentage2;
                    initialValues.value.ProfitPercentage3 = res.data.profitPercentage3;
                    initialValues.value.PublicPrice = formatFixedDecimal(res.data.publicPrice);
                    initialValues.value.AluminumPrice = formatFixedDecimal(res.data.buyerPrice);
                    initialValues.value.WholesalePrice = formatFixedDecimal(res.data.wholesalePrice);
                    initialValues.value.cu = "";//onChangeUnit(res.data.unitOfMeasurementId);
                    descriptionTab.value.fillComboSubfamilys(res.data.familyId);
                    descriptionTab.value.fillComboLines(res.data.familyId, res.data.subFamilyId);
                    generalPricingData.value.publicPrice = res.data.publicPrice > 0 ? getPriceIvaForType(res.data.publicPrice, res.data.taxValue) : 0.000;
                    generalPricingData.value.aluminumPrice = res.data.buyerPrice > 0 ? getPriceIvaForType(res.data.buyerPrice, res.data.taxValue) : 0.000;
                    generalPricingData.value.wholesalePrice = res.data.wholesalePrice > 0 ? getPriceIvaForType(res.data.wholesalePrice, res.data.taxValue) : 0.000;
                    generalPricingData.value.currentExistence = res.data.currentExistence;
                    newArticleModule.SET_EQUIVALENCIES([]);//asignar equivalencias del articulo
                }
            }).catch(reject => {
                getMessageError(reject, messageService);
            });

            url = `${controller}/PutProductImage`;
            ApiService.dowloadFile(url, articleId.value).then(res => {
                if(res.status == 200) {
                    const imageBlob = res.data;
                    urlImagen.value = URL.createObjectURL(imageBlob);
                }
            });
        }
        
        const onSubmit = async(formValues) => {
            let fData = new FormData();
            fData.append('Name', formValues.Name);
            fData.append('WarehouseId', formValues.WarehouseId);
            fData.append('FamilyId', formValues.FamilyId);
            fData.append('SubFamilyId', formValues.SubFamilyId);
            fData.append('LineId', formValues.LineId);
            fData.append('UnitOfMeasurementId', formValues.UnitOfMeasurementId);
            fData.append('Price', 0);
            fData.append('AlternateCode', formValues.AlternateCode);
            fData.append('SupplierKey', formValues.SupplierKey);
            fData.append('ProductKey', formValues.ProductKey);
            fData.append('Description', formValues.Description);
            fData.append('ColorId', formValues.ColorId);
            fData.append('SupplierId', formValues.SupplierId);
            fData.append('MinimumQuantity', formValues.MinimumQuantity);
            fData.append('MaximumQuantity', formValues.MaximumQuantity);
            fData.append('Weight', formValues.Weight);
            fData.append('CurrencyId', formValues.CurrencyId);
            fData.append('TaxId', formValues.TaxId);
            // fData.append('ColorPercentageId', formValues.ColorPercentageId); 
            
            fData.append('isInventory', formValues.isInventory);
            fData.append('ProfitPercentage', formValues.ProfitPercentage); 
            fData.append('SupplierPrice', formValues.SupplierPrice);
            fData.append('Cost', formValues.Cost);
            fData.append('DiscountRateSupplier', formValues.DiscountRateSupplier);
            fData.append('ProfitPercentage1', formValues.ProfitPercentage1);
            fData.append('ProfitPercentage2', formValues.ProfitPercentage2);
            fData.append('ProfitPercentage3', formValues.ProfitPercentage3);
            fData.append('AluminumPrice', formValues.AluminumPrice);
            fData.append('PublicCostSale', formValues.PublicCostSale);
            fData.append('PublicPrice', formValues.PublicPrice);
            fData.append('WholesalePrice', formValues.WholesalePrice);
            fData.append('Images', images);
            fData.append('Equivalences', equivalencies.value);
            if(currentAction.value == "N"){
                await saveNewArticle(fData);
            } else if(currentAction.value == "M") {
                await updateArticle(fData);
            }
        }

        const saveNewArticle = async(article) => {
            await axios.post(`${controller}`, article, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                if(res.status == 201 && res.data.id != ''){
                    backToMain();
                    messageService.success("La información se guardó exitosamente.");
                }
            }).catch(reject => {
                debugger
            });
        }

        const updateArticle = async(article) => {
            await axios.put(`${controller}/${articleId.value}`, article, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                if(res.status == 204){
                    backToMain();
                    messageService.success("La información se actualizó exitosamente.");
                }
            }).catch(reject => {
                    debugger
            });
        }

        const onImageLoaded = (imageArray) => {
            images = imageArray;
        }

        const onupdgeneralPricingData = (idprice, pricecomponent, typeprice) => {
            switch(typeprice)
            {
                case 1:
                    generalPricingData.value.publicPrice = idprice
                    form.value.setFieldValue('PublicPrice', pricecomponent)
                    break;
                case 2:
                    generalPricingData.value.aluminumPrice = idprice
                    form.value.setFieldValue('AluminumPrice', pricecomponent)
                    break;
                case 3:
                    generalPricingData.value.wholesalePrice = idprice
                    form.value.setFieldValue('WholesalePrice', pricecomponent)
                    break;
                case 4:
                    form.value.setFieldValue('Cost', pricecomponent)
                    break;
            }
        }

        const getPriceIvaForType = (priceType, taxValue) => {
            //return Math.round((priceType * 1.16) * 100 ) / 100
            let calc_Iva = 1;
            if(taxValue > 0){
                calc_Iva = (taxValue / 100);
                calc_Iva = (priceType * calc_Iva)
            }
            return (taxValue > 0 ? parseFloat(priceType + calc_Iva).toFixed(2) : parseFloat(priceType).toFixed(2))
        }

        const formatFixedDecimal = (value) => {
            let numValue = typeof value === "string" ? parseFloat(value) : value; 
            return numValue > 0 ? numValue.toFixed(4) : 0; 
        }
        
        /* const onChangeUnit = (val) => {
            let url = `MeasurementUnits/${val}`;
            ApiService.get(url).then(res => {
                form.value.setFieldValue('cu', res.data.code);
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        } */

        return {
            /** CONST */
            validationSchema
            /** REFS */
            , form
            , submitBtn
            , descriptionTab
            , equivalencies
            /** COMPUTEDS */
            , getTitle
            , activeDescriptionTab
            , activePricingTab
            , activeGeneralTab
            , getCurrentAction
            , getForm
            , getInitialValues
            , getReadOnly
            , getGeneralPricingData
            , articleId
            , urlImagen

            /** METHODS */
            , changeTab
            , backToMain
            , onSubmit
            //, SaveData
            , onImageLoaded
            , onupdgeneralPricingData
        }
    },
})
</script>

<i18n>
{
    "es": {
        "TITLES": {
            "ADD": "Nuevo Artículo",
            "EDIT": "Editar Artículo",
            "VIEW": "Visualizar Artículo"
        }
    }
}
</i18n>
