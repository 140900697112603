export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "TITLES": {
          "EQUIVALENCIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equivalencias"])},
          "TITLESOUTPUST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salidas"])},
          "INPUST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entradas"])},
          "SALES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventas"])},
          "PURCHASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compras"])}
        }
      }
    }
  })
}
