export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "LABELS": {
          "ARTICLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producto"])},
          "UNIT_MESUREMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidad de medida"])},
          "QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad/equivalencia"])}
        },
        "PLACE_HOLDERS": {
          "ARTICLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar por nombre"])},
          "UNIT_MESUREMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar producto"])}
        },
        "BUTTONS": {
          "ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar"])},
          "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
          "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
        }
      }
    }
  })
}
