import Modules from "@/store/enums/Modules";
import { Module, Mutation, MutationAction, VuexModule, getModule } from "vuex-module-decorators";
import store from "@/store"
import OptionSelect from "@/models/shared/OptionSelect";
import NewArticleState from '../../models/NewArticle';
import Equivalence from '@/models/article/equivalence/Equivalence';
import ProductsService from '@/core/services/products/ProductsService';
import ProductInfoOption from '@/models/products/ProductInfoOption';
import EquivalenceRow from '@/models/article/equivalence/EquivalenceRow';
import PaginationRequest from "@/models/general/PaginationRequest";

enum Mutations {
    SET_PRODUCT_SELECT = 'SET_PRODUCT_SELECT'
    , SET_EQUIVALENCIES = "SET_EQUIVALENCIES"
    , EDIT_EQUIVALENCE = 'EDIT_EQUIVALENCE'
    , RESET_INITIAL_VALUE = 'RESET_INITIAL_VALUE'
    , CHANGE_PAGE = 'CHANGE_PAGE'
    , RESET_EQUIVLENCIES = 'RESET_EQUIVLENCIES'
    , SET_INITIAL_VALUE = 'SET_INITIAL_VALUE'
}

enum Actions {
    ADD_EQUIVALENCE = 'ADD_EQUIVALENCE'
    , REMOVE_EQUIVALENCE = 'REMOVE_EQUIVALENCE'
}

@Module({ dynamic: true, store, namespaced: true, name: Modules.NewArticle})
class NewArticleModule extends VuexModule implements NewArticleState{
    paginations = {
        equivalencies: {
            currentPage: 1
            , pageSize: 5
        } as PaginationRequest
    }
    selects = {
        products: [] as ProductInfoOption[] 
        , unitOfMeasurement: [] as OptionSelect[]
    };
    equivalencies = [] as EquivalenceRow[];
    loading = false;
    productSelect: undefined | ProductInfoOption;
    initialValues: undefined | Equivalence;

     /**
     * equivalencias
     * @returns Array<OptionSelect>
     */
    get getEquivalenciesTable(): Array<Equivalence> {
        //dividimos las equivalencias por medio del paginador, este paginador es diferente por qeu depende de los articulos(productos) agregados
        const pageSize = this.paginations.equivalencies.pageSize;
        const currentPage = this.paginations.equivalencies.currentPage;

        const startElm = ((currentPage - 1) * pageSize)
        const totalItem = currentPage * pageSize;

        return this.equivalencies.slice(startElm, totalItem);
    }
    get isEditEquivalence(): boolean { 
        return this.initialValues != undefined
    }
     get getInitialValues(): Equivalence | undefined { 
         return this.initialValues;
    }

     @Mutation
    async [Mutations.SET_PRODUCT_SELECT](product: ProductInfoOption | undefined) {
        if (product && product.id != '') {
            this.selects.unitOfMeasurement = [{
                id: product.unitOfMeasurementId
                , name: product.unitOfMeasurementName
            } ] as OptionSelect[]
            this.productSelect = product;
        }
    }

     @Mutation
    async [Mutations.SET_EQUIVALENCIES](equivalencies: EquivalenceRow[]) {
        if (equivalencies != undefined && equivalencies != null) {
            this.equivalencies = equivalencies;
        }
    }
     @Mutation
    async [Mutations.RESET_INITIAL_VALUE]() {
         this.initialValues = undefined; 
    }
     @Mutation
    async [Mutations.SET_INITIAL_VALUE](value :Equivalence) {
         this.initialValues = value; 
    }
    
    @Mutation
    async [Mutations.CHANGE_PAGE](currentPage: number) {
         this.paginations.equivalencies.currentPage = currentPage; 
    }
    @Mutation
    async [Mutations.RESET_EQUIVLENCIES]() {
        this.paginations.equivalencies.currentPage = 1;
        this.equivalencies = [];
        //this.context.commit(Mutations.RESET_INITIAL_VALUE)
    }
     @MutationAction
     async [Mutations.EDIT_EQUIVALENCE](productId: string) {
         const equivalence = this.equivalencies.filter(e => e.productId == productId).shift();
         const selects = this.selects;

         let products = this.selects.products;
         if (equivalence != undefined && equivalence != null) { 
             //si existe la equivalencia a editar entonces la subimos al store
             products = [{
                 id: equivalence.productId
                 , name: equivalence.productName
                 , unitOfMeasurementId: equivalence.unitOfMeasurementId
                 , unitOfMeasurementName: equivalence.unitOfMeasurementName
                }] as ProductInfoOption[]
            this.context.commit(Mutations.SET_PRODUCT_SELECT, products[0]);
            this.context.commit(Mutations.SET_INITIAL_VALUE, equivalence);
         }

         return {
             selects: {
                 ...selects
                 , products: products
             },
         }
    }


    @MutationAction
    async [Actions.ADD_EQUIVALENCE](form: Equivalence) {
        const equivalencies = [...this.equivalencies]
        if (equivalencies.findIndex(e => e.productId == form.productId) < 0) {
            equivalencies.push({
                ...form
                , productName: this.productSelect?.name ?? ''
                , productId: this.productSelect?.id ?? ''
                , unitOfMeasurementName: this.productSelect?.unitOfMeasurementName ?? ''
            } as EquivalenceRow);
        } else { 
            //si ya existe es edicion
            const index = equivalencies.findIndex((obj => obj.productId == form.productId));
            equivalencies[index].equivalece = form.equivalece;
            this.context.commit(Mutations.RESET_INITIAL_VALUE)
        }
        return { equivalencies: equivalencies}
    }
     @MutationAction
     async [Actions.REMOVE_EQUIVALENCE](productId: string) {
        let equivalencies = [...this.equivalencies]
        equivalencies = equivalencies.filter(e => e.productId != productId);
        return { equivalencies: equivalencies }
    }
    

    @MutationAction
     async getComboProduct(filterName: string) {
        const comboOptions = await serviceProduct.searchProducts(filterName);
        const selects = { ...this.selects }
        const products = (comboOptions.data ?? []);
        /*const unitsOfMeasurement = (comboOptions.data ?? []).map(p => { 
            return {
                id: p.id
                , name: p.name
            } as OptionSelect
        })*/
        return { selects: { ...selects, products: products}}
    }

 
}

const serviceProduct = new ProductsService();

export default getModule(NewArticleModule);