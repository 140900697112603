
import { defineComponent, computed, ref, onBeforeMount, toRefs, reactive, onMounted } from 'vue';
import { Field } from "vee-validate";
import SelectOption from "../../../components/c-form-elements/SelectOption.vue";
import ApiService from "@/core/services/ApiService";
import { getMessageError } from '@/core/helpers/messageFromPulmeros';
import SwalMessageService from "@/core/services/SwalMessageService";
import { parse } from 'path';

export default defineComponent({
    name: "pricing-tab-content",
    components: {
        Field,
        SelectOption,
    },
    props: {
        mode: {
            type: String,
            required: false,
            default: () => "V"
        },
        initialValues: {
            type: Object,
            required: false
        },
    },
    setup(props, context) {
        const {mode, initialValues} = toRefs(props);
        const messageService = new SwalMessageService();
        let currencyTypes = ref([]);
        let taxes = ref([]);
        let percentageColors = ref([]);
        
        const costreal = ref(0);
        let supplierPrice = 0;
        let supplierDiscount = 0;
        let realCost = 0;
        let marginProfitOne = 0;
        let marginProfitTwo = 0;
        let marginProfitThree = 0;
        let dIva = 16;

        //HOOKS
        onBeforeMount(async() => {
            await getComboOptions("Currency", currencyTypes);
            await getComboOptions("Taxes", taxes);
            await getComboOptions("ColorPercentages", percentageColors);
        });  
       
        onMounted(()=> {
            //console.log(initialValues.value)
        })
        //COMPUTEDS
        const readOnly = computed(() => mode.value == "V");
        const currencyTypeOptions = computed(() => currencyTypes.value);
        const taxesOptions = computed(() => taxes.value);
        const percentageColorOptions = computed(() => percentageColors.value);

        //METHODS
        const getComboOptions = async(resource, container, parentId = "") => {
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let url = `${resource}/${parentParam}combo`;
            ApiService.get(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const handlerupdprice = (evt: number) => {
            supplierPrice = evt;
            realCost = supplierPrice;
            setAssigValForm();
            if(supplierDiscount > 0){
                let discount = supplierPrice * ( supplierDiscount / 100)
                realCost = parseFloat((supplierPrice - discount).toFixed(4))//Math.round((supplierPrice - discount) * 100) / 100            
            }
            context.emit("onupdgeneralPricingData", 0, realCost, 4)
            costreal.value = realCost;
            getNetPrice(realCost);
        }

        const handlerupddiscount = (evt: number) => {
            supplierDiscount = evt            
            setAssigValForm();
            if(supplierDiscount > 0){
                let discount = supplierPrice * ( supplierDiscount / 100)
                realCost = parseFloat((supplierPrice - discount).toFixed(4))//Math.round((supplierPrice - discount) * 100) / 100               
            }else{
                realCost = supplierPrice
            }
            context.emit("onupdgeneralPricingData", 0, realCost, 4)
            costreal.value = realCost;
            getNetPrice(realCost);
        }

        const setAssigValForm = () => {
            if(mode.value != "N"){
                if(supplierPrice == 0)
                    supplierPrice = initialValues.value ? initialValues.value.SupplierPrice : 0;
                if(supplierDiscount == 0)
                    supplierDiscount = initialValues.value ? initialValues.value.DiscountRateSupplier : 0 ;
                if(dIva == 0)
                    dIva = initialValues.value ? initialValues.value.taxValue : 0;
            }
        }

        const handlemarginone = (pricemarginone: number) => {
            marginProfitOne = pricemarginone
            let publicSellingPrice = getPriceForType(marginProfitOne)
            context.emit("onupdgeneralPricingData", getPriceIvaForType(publicSellingPrice), publicSellingPrice, 1)
        }
        
        const handlemargintwo = (pricemargintwo: number) => { 
            marginProfitTwo = pricemargintwo
            let alumSellingPrice = getPriceForType(marginProfitTwo)
            context.emit("onupdgeneralPricingData", getPriceIvaForType(alumSellingPrice), alumSellingPrice, 2)
        }
        
        const handlemarginthree = (pricemarginthree: number) => { 
            marginProfitThree = pricemarginthree
            let wholesalePrice = getPriceForType(marginProfitThree);
            context.emit("onupdgeneralPricingData", getPriceIvaForType(wholesalePrice), wholesalePrice, 3)
        }

        const getNetPrice = (priceform: number )=>{
            let publicSellingPrice = 0
            let alumSellingPrice = 0
            let wholesalePrice = 0
            if(realCost == 0){
                realCost = priceform
            }

            if(mode.value != "N")
            {
                if(realCost == 0)
                    realCost = initialValues.value ? initialValues.value.Cost : 0 ;
                if(marginProfitOne == 0)
                    marginProfitOne = initialValues.value ? initialValues.value.ProfitPercentage1 : 0 ;
                if(marginProfitTwo == 0)
                    marginProfitTwo = initialValues.value ? initialValues.value.ProfitPercentage2 : 0 ;
                if(marginProfitThree == 0)    
                    marginProfitThree = initialValues.value ? initialValues.value.ProfitPercentage3 : 0 ;
            } 

            if(marginProfitOne > 0)
            {
                publicSellingPrice = getPriceForType(marginProfitOne)
                context.emit("onupdgeneralPricingData", getPriceIvaForType(publicSellingPrice), publicSellingPrice, 1)
            }
            
            if(marginProfitTwo > 0)
            {
                alumSellingPrice = getPriceForType(marginProfitTwo)
                context.emit("onupdgeneralPricingData", getPriceIvaForType(alumSellingPrice), alumSellingPrice, 2)
            }

            if(marginProfitThree > 0)
            {
                wholesalePrice = getPriceForType(marginProfitThree);
                context.emit("onupdgeneralPricingData", getPriceIvaForType(wholesalePrice), wholesalePrice, 3)
            }
        }

        const getPriceForType = (priceType: number) => {
            let priceaux = 0;
            priceaux = (100 - priceType) / 100
            //return Math.round((realCost / priceaux) * 100) / 100
            return parseFloat((realCost / priceaux).toFixed(4))
            //const priceaux = getPrice(realCost, priceType);
        }

        const getPriceIvaForType = (priceType: number) => {
            //return Math.round((priceType * dIva) * 100 ) / 100
            let calc_Iva = 0;
            if(dIva > 0){
                calc_Iva = (dIva / 100);
                calc_Iva = (priceType * calc_Iva)
            }
            return parseFloat(( priceType + calc_Iva).toFixed(2))
        }

        const formatFixedDecimal = (value) => {
            let numValue = typeof value === "string" ? parseFloat(value) : value; 
            return numValue.toFixed(2); 
        }

        const onChangeTaxPrices = (val) => {
            //console.log("tax: " + val)
            let url = `Taxes/${val}`;
            ApiService.get(url).then(res => {
                dIva = res.data.value;
                getNetPrice(0);
               // console.log("Response tax: " + dIva)
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const getPrice = async(cost, profitPercentage) => {
            let url = `Products/getPrice?cost=${cost}&profitPercentage=${profitPercentage}`;
            return ApiService.get(url).then(res => res.data);
            //https://apiqa.aluminiosarmando.info/api/Products/getPrice?cost=307.41&profitPercentage=35.26
        }

        return {
            readOnly
            , currencyTypeOptions
            , taxesOptions
            , percentageColorOptions
            
            , costreal
            , handlerupdprice
            , handlerupddiscount
            , handlemarginone
            , handlemargintwo
            , handlemarginthree
            , onChangeTaxPrices

            , realCost
        }
    },
})
