<template>
    <div class="tab-content">
        <div class="tab-pane fade show" id="tab_article_general" role="tab-panel">
            <div class="card card-flush py-4">
                <div class="card-body pt-0">
                    <div class="d-flex flex-wrap gap-5 mt-10">
                        <div class="fv-row w-100 flex-md-root">
                            <label class="form-label">Descripción Detallada</label>
                            <Field name="Description" v-slot="{value, field, errorMessage}">
                                <el-form-item :error="errorMessage">
                                    <el-input v-bind="field" type="textarea" :rows="5"
                                        placeholder="Descripción Detallada" :validate-event="false" :disabled="readOnly"
                                        :model-value="value" />
                                </el-form-item>
                            </Field>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap gap-5 mt-10">
                        <div class="fv-row w-100 flex-md-root">
                            <label class="form-label">Mínimo</label>
                            <Field name="MinimumQuantity" v-slot="{value, field, errorMessage}">
                                <el-form-item :error="errorMessage">
                                    <el-input v-bind="field" type="text" placeholder="Cantidad Mínima"
                                        :validate-event="false" :disabled="readOnly" :model-value="value" />
                                </el-form-item>
                            </Field>
                        </div>
                        <div class="fv-row w-100 flex-md-root">
                            <label class="form-label">Máximo</label>
                            <Field name="MaximumQuantity" v-slot="{value, field, errorMessage}">
                                <el-form-item :error="errorMessage">
                                    <el-input v-bind="field" type="text" placeholder="Cantidad Máxima"
                                        :validate-event="false" :disabled="readOnly" :model-value="value" />
                                </el-form-item>
                            </Field>
                        </div>
                        <div class="fv-row w-100 flex-md-root">
                            <div style="display: flex;">
                                <div>
                                    <label class="form-label">Activo</label>
                                    <div class="form-check form-check-custom form-check-solid mb-2">
                                        <input class="form-check-input" type="checkbox" checked disabled />
                                    </div>
                                </div>
                                <div style="padding-left: 16px;">
                                    <label class="form-label">Inventario</label>
                                    <Field name="isInventory" v-slot="{ value, field, errorMessage }">
                                        <el-form-item :error="errorMessage"
                                            class="form-check form-check-custom form-check-solid mb-2">
                                            <el-switch v-bind="field" :validate-event="false" :disabled="readOnly"
                                                :model-value="value" inline-prompt active-text="Si"
                                                style="--el-switch-on-color: #FFD00E; --el-switch-off-color: #000000"
                                                inactive-text="No" />
                                        </el-form-item>
                                    </Field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, toRefs, computed } from 'vue';
import { Field } from "vee-validate";

export default defineComponent({
    name: "general-tab-content",
    components: {
        Field
    },
    props: {
        mode: {
            type: String,
            required: false,
            default: () => "V"
        }
    },
    setup(props) {
        const {mode} = toRefs(props);
        //COMPUTEDS
        const readOnly = computed(() => mode.value == "V");

        return {
            //Computeds
            readOnly
        }
    },
})
</script>
